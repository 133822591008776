import { Container, Form, Button, Modal } from 'react-bootstrap'
import { useState } from 'react';
import './App.css';
import logo from './img/logodatralabs.png';
import Swal from 'sweetalert2';
import axios from 'axios';
import Cookies from 'universal-cookie';
const moment = require ('moment/moment');
function Logeo() {

  const [user, setUser] = useState({ usuario: '', contrasena: '' });
  const [showModalDeclacion, setShowModalDeclacion] = useState(false);
  const [smShow, setSmShow] = useState(true);
  const onChangeUser = e => {
    const { value, name } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  }
  
  const cookie = new Cookies ();
  const access_token = cookie.get ('perv_v2_cookies_user_kdfoeinapjdkfawep');

  const queryDeclaracion = async (respuesta) =>{

    try {
      const body= {respuesta}      
      const {data} = await axios.post (
        process.env.REACT_APP_SERVIDOR + '/api/v2/list/usuarios/declaracion', body,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      
      if(data.message === 'success'){
        
        setUser({
            usuario: '',
            contrasena: '',
          });
          window.location.href= '/Menu';
      }


    } catch (error) {
      console.log ('Error loading users from server: ' + error.message);
    }

  }
  const queryDec = async (initialDate, finalDate) =>{

    try {
        const dataConsulta = await axios.post (
          process.env.REACT_APP_SERVIDOR + `/api/v2/list/declaracion`, {fechaInicio: initialDate, fechaFinal:finalDate},
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        setTimeout(() => {          
          if(dataConsulta.data.estado){
            setUser({
              usuario: '',
              contrasena: '',
            });
            window.location.href= '/Menu';
          }else{  
            setShowModalDeclacion(true);
            setSmShow(false);          
          } 
        }, 2000);

  
    } catch (error) {
      if(error.response.status === 403){
        window.location.reload();
      }
      console.log("error: "+error)
    }
  }
  const queryLogin = async () => {
    try {
      if (user.usuario === '') {
        showAlert('error', 'El usuario es obligatorio');
      } else if (user.contrasena === '') {
        showAlert('error', 'La contraseña es obligatorio');
      }else if (!aceptaTerminos) {
        showAlert('error', 'Debes aceptar la política de tratamiento de datos personales de datralabs sas.');
      }
       else {
        const { data } = await axios.post(process.env.REACT_APP_SERVIDOR + '/api/v2/login/pesv', user);

        if (data.estado) {
          const cookie = new Cookies();
          cookie.set('compania_user', data.id_compania, {path: '/'});
          cookie.set('nombre_user ', data.nombre, {path: '/'});
          cookie.set('nombre_empresa', data.nombre_empresa, {path: '/'});
          cookie.set('logo_empresa', data.logo, {path: '/'});
          cookie.set('perfil', data.perfiles, {path: '/'});

          cookie.set('perv_v2_cookies_user_kdfoeinapjdkfawep', data.access_token, { path: '/' });
          
          const fecha = new Date(); 
          let fechaActual = moment(fecha).format('YYYY-MM-DD');
          let initialDate ='';
          let finalDate ='';

          if(data.perfiles === 'Administrador' && data.declaracion === 'si'){

            if(data.fecha_declaracion === ""+fecha.getDay()){

              initialDate = moment (fechaActual + ' 00:00:00').format ('YYYY-MM-DD HH:mm:ss');
              finalDate = moment (fechaActual + ' 23:59:59').format ('YYYY-MM-DD HH:mm:ss');
            }else{
              
              finalDate = moment (fechaActual + ' 23:59:59').format ('YYYY-MM-DD HH:mm:ss');
              fechaActual = moment().subtract(5, 'd').format('YYYY-MM-DD')          
              initialDate = moment (fechaActual + ' 00:00:00').format ('YYYY-MM-DD HH:mm:ss');
            }  

            queryDec(initialDate, finalDate);
            
        }else{           
    
          setUser({
            usuario: '',
            contrasena: '',
          });
          window.location.href= '/Menu';
        }
        } else {
          showAlert('error', 'El usuario o contraseña son incorrectos');
        }

        
      }
    } catch (error) {
      console.log('Error while fetching login: ' + error.message);
    }
  }
  const cerrarModalCantidad = () => {
    setShowModalDeclacion(false);
  }
  
  const showAlert = (icono, mensaje) => {
    if (mensaje !== '') {
      Swal.fire({
        icon: icono,
        title: mensaje
      })
    }
  }

    const [aceptaTerminos, setAceptaTerminos] = useState(false);
    
    const handleTerminosClick = () => {
      window.open('https://datralabs.com/index.php/politica-de-tratamiento-de-datos-personales-de-datralabs-sas/', '_blank');
    };
  
  
  return (
    <>
      <Container>
        <Modal
          size="lg"
          show={smShow}
          aria-labelledby="contained-modal-title-vcenter"
          centered

        >
          <Modal.Header >

            <Modal.Title id="example-modal-sizes-title-sm"  >
              <h1><img src={logo} alt="logo" /></h1>

              <h1 style={{textAlign: 'center'}}>Iniciar Sesión</h1>

            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Usuario</Form.Label>
                <Form.Control name='usuario' type="email" placeholder="Ingrese Usuario" value={user.usuario} onChange={onChangeUser} />

              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Contaseña</Form.Label>
                <Form.Control name='contrasena' type="password" placeholder="Ingrese Contraseña" value={user.contrasena} onChange={onChangeUser} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
  <Form.Check
    type="checkbox"
    label={
      <span onClick={() => {
        if (aceptaTerminos) {
          window.open('https://datralabs.com/index.php/politica-de-tratamiento-de-datos-personales-de-datralabs-sas/', '_blank');
        }else  {
          window.open('https://datralabs.com/index.php/politica-de-tratamiento-de-datos-personales-de-datralabs-sas/', '_blank');
        }
      }}>
        Acepta la política de tratamiento de datos personales de datralabs sas
      </span>
    }
    checked={aceptaTerminos}
    onChange={() => setAceptaTerminos(!aceptaTerminos)}
  />
</Form.Group>
              <div style={{ textAlign: 'center' }}>
                <Button variant="primary" id='botonConsulta' onClick={() => queryLogin()}>
                  Iniciar
                </Button>

              </div>
              
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          show={showModalDeclacion}
          backdrop="static"
          keyboard={false}
          centered
          onHide={cerrarModalCantidad}

        >
          <Modal.Body>
            <h4>DECLARACIÓN DE RESPONSABILIDAD</h4>
            <Form.Label style={{textAlign: 'justify'}}>
            <br/>
            Declaro como responsable de flota:<br/><br/>
            He revisado todos los chequeos preoperativos de los vehículos, he contactado a los conductores, he gestionado y solucionado las dificultades que estos manifiestan respecto a sus vehículos.<br/><br/>
            He programado los mantenimientos periódicos y correctivos de mi flota con antelación.<br/><br/>
            Estoy al día con la documentación de ley de los vehículos a mi cargo y las tareas de la plan anual de trabajo correspondientes a esta semana.
            </Form.Label>
            
            <div style={{width: '100%'}}>
              <Button style={{margin: '10px', fontSize: '20px', fontWeight: 'bold'}} variant="outline-primary" onClick={()=>queryDeclaracion('si')}>Aceptar</Button>
              {/* <Button style={{margin: '10px', fontSize: '20px', fontWeight: 'bold'}} variant="outline-primary" onClick={()=>queryDeclaracion('no')}>Rechazar</Button> */}
            </div>
          </Modal.Body>
        </Modal>

      </Container>
    </>
  );
}

export default Logeo;

